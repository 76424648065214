<template>
  <v-container>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      right
      color="blue-grey lighten-5"
      class="elevation-0"
      width="850"
      temporary
    >
      <cliente />
    </v-navigation-drawer>

    <v-card>
      <v-toolbar
        dark
        dense
        color="purple"
      >
        <v-col cols="1">
          <v-avatar
            tile
            dense
            size="55"
            color="purple lighten-2"
            class="avatar rounded-lg"
          >
            <v-icon
              color="white"
              x-large
              class="mb-2 ml-1"
            >
              mdi-office-building-cog
            </v-icon>
          </v-avatar>
        </v-col>
        <h3> Catalogo de clientes</h3>
        <v-spacer />
        <v-col cols="3">
          <v-text-field
            v-model="search"
            class="mx-2"
            solo-inverted
            dense
            dark
            color="purple"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details
          />
        </v-col>
        <v-btn
          small
          class="ma-4"
          color="success"
          @click="consultar"
        >
          Consultar <v-icon>mdi-update</v-icon>
        </v-btn>
        <v-btn
          align="right"
          color="orange"
          dark
          small
          class="ma-2"
          @click.stop="cliente()"
        >
          Nuevo <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <!-- EXCEL -->
        <v-btn
          align="right"
          class="ma-4"
          small
          color="green"
          dark
        >
          <download-excel
            :data="clientes"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="CatClientes.xls"
          >
            Excel
            <v-icon class="ma-1">
              mdi-download
            </v-icon>
          </download-excel>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-row>
      <v-col>
        <v-card elevation="10">
          <v-subheader class="">
            <h2 class="ma-4 pt-2 purple--text">
              Clientes
            </h2>
            <v-spacer />
          </v-subheader>

          <v-row>
            <v-col>
              <v-data-table
                dense
                heigth="400"
                :headers="headers"
                :items="clientes"
                :search="search"
              >
                <template v-slot:item="{ item }">
                  <tr @click.stop.prevent="editar(item)">
                    <td>{{ item.idweb }}</td>
                    <td>{{ item.nomcli }}</td>
                    <td>{{ item.estado }}</td>
                    <td>{{ item.atvent }}</td>
                    <td>{{ item.email1 }}</td>
                    <td>{{ item.rfc }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from 'vuex'
import cliente from "@/views/crm/clientes/cliente"
export default {
  components:{
    cliente
  },
  data () {
    return {
      drawer: false,
      search: '',
      clientes: [],
      headers: [
        { text: 'ID', value: 'idweb' },
        { text: 'Nombre del cliente', value: 'nomcli' },
        { text: 'Estado', value: 'estado' },
        { text: 'Contacto', value: 'atvent' },
        { text: 'Email', value: 'email1'},
        { text: 'RFC', value: 'rfc' },
      ],
      json_fields: {
        Id: "idweb",
        Nombre: "atvent",
        Empresa: "nomcli",
        Telefono: "telefono",
        Email: "email",
        Estado: "estado",
        RFC: "rfc",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      json_data: [],
    }
  },
  created(){
    this.consultar()
  },


  methods: {
    ...mapActions('oportunidad',['setOportunidad']),
    consultar(){
      // limpiar
      this.clientes= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      this.$http.get("v2/clientes" ).then(response=>{
        if (response.data.error===null){
          this.clientes = response.data.result

          //this.proyectos.forEach( element => {
          //element.fechastart = element.fechastart.substr(0,10)
          //element.hora = element.fechastart.substr(11,17)
          //})

          console.log("clientes", this.clientes)
        }
          
      }).catch(error=>{
        	console.log(error)
      })
    },
    editar(item){
      console.log("Renglonitem", item)
      this.$router.push({name:'cliente', params: {cliente:item}})
    },
    cliente(){
      // console.log("oportunidad seleccionada",opor1)
      // Mandar llamar al Action.
      // this.setOportunidad (opor1)

      this.drawer = !this.drawer
    }
  }
}

</script>

<style scoped>
.avatar{
  top: -10px;
}
</style>