<template>
  <v-container class="pa-0">
    <v-row class="ma-0">
      <v-col
        cols="12"
      >
        <v-toolbar
          dark
          dense
          color="white"
          class="rounded-lg"
        >
          <h3 class="purple--text">
            {{ titulo }}
          </h3>
          <v-spacer />
          <v-btn
            align="right"
            color="info"
            dark
            small
            class="ma-1"
            @click="regresar"
          >
            <v-icon>mdi-reply</v-icon>
          </v-btn>

          <v-btn
            align="right"
            color="success"
            dark
            small
            class="ma-1"
            @click="saveInfo"
          >
            <v-icon>mdi-check-circle</v-icon> PROCESAR
          </v-btn>
        </v-toolbar>

        <v-row class="mt-3 mb-1">
          <v-col cols="12">
            <v-card
              color="purple"
              dark
            >
              <v-row class="mx-2">
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  sm="12"
                >
                  <v-text-field
                    v-model="nomcli"
                    class="mt-2"
                    dense
                    hide-details
                    label="Nombre del ciente"
                  />
                </v-col>
                <v-spacer />
                <v-col
                  cols="12"
                  md="3"
                  lg="3"
                  sm="12"
                >
                  <!-- <v-select
                    v-model="precioutil"
                    dense
                    class="mt-2"
                    :items="precios"
                    hide-details
                    label="Listas de precios"
                  /> -->
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                  lg="3"
                  sm="12"
                >
                  <v-text-field
                    v-model="numcli"
                    dense
                    outlined
                    readonly
                    hide-details
                    prepend-inner-icon="person"
                    label="ID Cliente"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>


        <v-row class="mb-2">
          <v-col
            cols="12"
            md="8"
            lg="8"
            sm="12"
          >
            <v-card
              class="rounded-lg"
            >
              <v-row class="mx-2">
                <v-col
                  cols="12"
                  md="12"
                  lg="6"
                  sm="12"
                >
                  <h4>Atencion en ventas</h4>
                  <v-text-field
                    v-model="atvent"
                    label="Contacto de ventas"
                    hide-details
                  />
                  <v-text-field
                    v-model="email1"
                    label="Email"
                    hide-details
                  />
                  <v-text-field
                    v-model="telefono"
                    hide-details
                    label="Telefono"
                  />
                </v-col>
                <v-divider vertical />
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  sm="12"
                >
                  <h4>Atencion en cobranza</h4>
                  <v-text-field
                    v-model="atcobr"
                    label="Contacto cobranza"
                    hide-details
                  />
                  <v-text-field
                    v-model="email2"
                    label="Email"
                    hide-details
                  />
                  <v-text-field
                    v-model="fax"
                    hide-details
                    label="Telefono"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          
          <v-col
            cols="12"
            md="4"
            lg="4"
            sm="12"
          >
            <v-card
              height="20"
              flat
              color="transparent"
            >
              <v-img
                class="imagen1"
                src="@/assets/cliente1.png"
                contain
              />
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col
            cols="12"
            md="8"
            lg="8"
            sm="12"
          >
            <v-card shaped>
              <v-row class="mx-1">
                <v-col>
                  <h4 class="">
                    Datos fiscales<v-icon class="mb-2 ml-2">
                      mdi-store
                    </v-icon>
                  </h4>
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                      lg="8"
                    >
                      <v-text-field
                        v-model="calle"
                        hide-details
                        label="Calle"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="numext"
                        label="Num EXT"
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <v-text-field
                        v-model="numint"
                        label="Num INT"
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      <v-text-field
                        v-model="colonia"
                        label="Colonia"
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      <v-text-field
                        v-model="estado"
                        hide-details
                        label="Estado"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      <v-text-field
                        v-model="ciudad"
                        hide-details
                        label="Ciudad"
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      <v-text-field
                        v-model="rfc"
                        label="RFC"
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      <v-text-field
                        v-model="cp"
                        label="Codigo Postal"
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-card shaped>
                  <v-row class="mx-1">
                    <v-col>
                      <v-select
                        label="Vendedor"
                        hide-details
                        :items="vend"
                      />
                      <v-select
                        label="Zona"
                        hide-details
                        :items="zonas"
                      />
                      <v-select
                        label="Clasificacion"
                        :items="clas"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-toolbar
      color="purple"
      class="mt-10"
      height="50"
    />
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default{
  data(){
    return{
      precios: ['1', '2','3','4'],
      zonas:['Norte','Sur','Este','Oeste'],
      clas:['A','B','C','D'],
      vend:['Hachi Gutierrez','Nilo Gutierrez'],

      snackbar: false,
      numcli:'',
      nomcli:'',
		  precioutil:'',
		  atvent:'',
		  
		  email1:'',
      telefono:'',
      atcobr:'',
      email2:'',
      fax:'',
      calle:'',
      numint:'',
      numext:'',
      colonia:'',
      estado:'',
      ciudad:'',
      rfc:'',
      cp:'',
		  nModo: 1,
      titulo:'',
    }
  },
	

  computed:{
	  validaInfo(){
	    return  this.nomcli   !== ''  &&
			this.numcli !== '' &&
			this.calle !== '' &&
      this.numint  !== '' &&
      this.estado !== '' &&
      this.rfc !== '' &&
      this.cp !== ''
	  },
  },

  created(){
    // this.idcita = ''
    console.log('router', this.$route.params )
    this.numcli = this.$route.params.cliente

	    //MODO EDITAR
	    if ( this.numcli !== undefined ){
	        this.nModo=2
	        this.readonly= true

	        this.cargarInfo(this.$route.params.cliente)
	        this.modo = 'Editar'
      this.titulo = 'Editar cliente'

	     } else {
	     		console.log("nuevo")
	        //VALORES INICIALES ALTA
	        this.nModo = 1
	        this.modo  = 'Nuevo'
      this.titulo = 'Nuevo cliente'
	    }

  },
  methods:{
    ...mapActions("snackbar", ["showSnack"]),

    cargarInfo(cliente) {
      console.log("cliente", cliente)
      this.idweb = cliente.idweb
      this.numcli	    = cliente.numcli
      this.nomcli	    = cliente.nomcli
      this.precioutil	= cliente.precioutil
      this.atvent 	  = cliente.atvent
      this.email1	    = cliente.email1
      this.telefono	  = cliente.telefono
      this.atcobr     = cliente.atcobr
      this.email2 		=	cliente.email2
      this.fax 		    =	cliente.fax
      this.calle	    = cliente.calle
      this.numint	    = cliente.numint
      this.numext	    = cliente.numext
      this.estado  = cliente.estado
      this.colonia	  = cliente.colonia
      this.ciudad     = cliente.ciudad
      this.rfc 	      =	cliente.rfc
      this.cp	        = cliente.cp
    },

    saveInfo(){
      console.log("saveInfo")
      if (!this.validaInfo){
	    	  this.showSnack({
	          text: "Debes registrar los datos fiscales del cliente",
	          color: "orange",
	          timeout: 2000,
        	});
	     	  return
		    }

      var payload = {
        numcli:     this.numcli,
        nomcli:     this.nomcli,
        precioutil: this.precioutil,
        atvent:     this.atvent,
        email1:     this.email1,
        telefono:   this.telefono,
        atcobr:     this.atcobr,
        email2:     this.email2,
        fax:        this.fax,
        calle:      this.calle,
        numint:     this.numint,
        numext:     this.numext,
        colonia:    this.colonia,
        estado:     this.estado,
        ciudad:     this.ciudad,
        rfc:        this.rfc,
        cp:         this.cp,
      }

      console.log("payload", payload)
      // this.addCita(payload)

      //NUEVO
      if (this.nModo == 1) {
				   //Dedinir db name
        this.$http.post('v2/insert.cliente', payload).then( response =>{
		        console.log("response" ,response)

		        this.showSnack({
	            text: "Cliente agregado correctamente",
	            color: "green",
	            timeout: 2000,
	          });

		        this.limpiarProyecto()
          // setTimeout (() => this.$router.push({name:'catclientes'}),2000);
        }).catch(error=>{
          	// this.snackbar = true ;
          // this.color="error"
          // this.text = "Problemas para grabar el cliente"
          console.log(error)
       			this.showSnack({
	            text: "Problema para agregar cliente"+ error,
	            color: "red",
	            timeout: 2000,
	          });
   
        })
      }

      //EDITAR
      if ( this.nModo == 2 ){
        this.$http.put('v2/update.cliente/' + this.idweb, payload).then(response =>{
          this.showSnack({
	            text: "Cliente actualizado correctamente	",
	            color: "green",
	            timeout: 2000,
	          });
          setTimeout (() => this.$router.push({name:'catclientes'}),2000);

        }).catch(error=>{
          	this.showSnack({
	            text: "Problema para actualizar cliente."+ error,
	            color: "red",
	            timeout: 2000,
	          });
          console.log(error)
        })
      }

    },

    regresar(){
				 this.$router.push({name:'catclientes'})
    },

    limpiarProyecto(){
      this.numcli = ''
      this.nomcli = ''
      this.precioutil = ''
      this.atvent = ''
      this.email1 = ''
      this.telefono = ''
      this.atcobr = ''
      this.email2 = ''
      this.fax = ''
      this.calle = ''
      this.numint = ''
      this.numext = ''
      this.colonia = ''
      this.estado = ''
      this.ciudad = ''
      this.rfc = ''
      this.cp = ''
    }
  }
			


}
</script>

<style  scoped>
  .imagen1{
    top: -45px;
  }
</style>